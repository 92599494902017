import React from 'react';
import './contact.css';

const Contact = () => {


    return(
        <div id='contact-container'>
            <h2 id='contact-text'>Email Us:<br />taconicchronic@gmail.com</h2>
        </div>
    )
}

export default Contact;
