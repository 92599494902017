import React from 'react';

import './about.css';

const About = props => {

    return(
        <div id='about-container'>
            <div id='about'>
                <h1 id='about-text'>Coming Soon</h1>
            </div>
        </div>
    )
};

export default About;